import AOS from 'aos';

"use strict";

// Header height calculation
const headerHeightCalcukation = () => {
    let header = document.querySelector('.new-header');
    let headerHeight = header.offsetHeight;
    header.setAttribute('data-header-height', headerHeight + 'px');
}
window.addEventListener('load', headerHeightCalcukation);
window.addEventListener('resize', headerHeightCalcukation);

// Header sticky
const scroll = $(window).scrollTop();
$("header").toggleClass("sticky", scroll > 50);

$(window).on('scroll', () => {
    const scroll = $(window).scrollTop();
    $("header").toggleClass("sticky", scroll > 50);
});

// Menu trigger
$('.menu-icon').on('click', (event) => {
    $('html, header, .header-main-menu-area').toggleClass('menu-open');
    $(event.target).closest('.menu-trigger').toggleClass('menu-open');
});


// $('.header-main-menu .navbar > li.menu-item-has-children').find('.submenu-wrap').first().addClass('open-submenu');



// Submenu open
function submenu_open_mobile(){

    if ($(window).width() < 991) {

        $('.menu-item-has-children > a').on('click', function(e){
            let parentUlLength = $(this).parents('ul').length;
            $(this).closest('.header-main-menu').find('ul').not($(this).closest('li').find('ul').first()).removeClass('active-ul');
            $(this).closest('li').find('ul').first().addClass('active-ul');
            $(this).closest('ul').find('li').removeClass('active-li');
            $(this).closest('li').addClass('active-li');
            $(this).closest('.header-main-menu').addClass('open-submenu-'+parentUlLength);
            // alert(l);
            e.preventDefault();
            e.stopPropagation();
        });
        
        $('.back-menu-button').on('click', function(e){
            let parentUlLength = $(this).parents('ul').length - 1;
            $(this).closest('.header-main-menu').removeClass('open-submenu-'+parentUlLength);
            // setTimeout(() => {
            //     $(this).closest('ul.sub-menu').parent('li').removeClass('mega-toggle-on');
            // }, 700)
            e.preventDefault();
            e.stopPropagation();
        });
    } else {
        
        // let menu_timeout;
        // let submenu_timeout;
        // $('.header-main-menu .navbar > li.menu-item-has-children').on('mouseenter', function(e){
        //     let currentMainMenu = $(this);
        //     $(this).find('.submenu-wrap').first().addClass('open-submenu');
            
        // });
        // $('.header-main-menu .navbar > li.menu-item-has-children').on('mouseleave', function(e){
        //     let currentMainMenu = $(this);
        //     // menu_timeout = setTimeout(function(){
        //         currentMainMenu.find('.submenu-wrap').first().removeClass('open-submenu');
        //         currentMainMenu.find('.submenu-wrap').first().find('.submenu-wrap').first().removeClass('open-submenu');
        //     // }, 300);
        // });

        // $('.header-main-menu .navbar > li.menu-item-has-children .sub-menu > li.menu-item-has-children').on('mouseenter', function(){
        //     let currentSubMenu = $(this);
        //     currentSubMenu.find('.submenu-wrap').first().addClass('open-submenu');

        //     clearTimeout(submenu_timeout);

        //     $('.header-main-menu .navbar > li.menu-item-has-children .sub-menu > li.menu-item-has-children').not(this).find('.submenu-wrap').removeClass('open-submenu');
        // });
        // $('.header-main-menu .navbar > li.menu-item-has-children .sub-menu > li.menu-item-has-children').on('mouseleave', function(){
        //     let currentSubMenu = $(this);
        //     submenu_timeout = setTimeout(function(){
        //         currentSubMenu.find('.submenu-wrap').removeClass('open-submenu');
        //     }, 300);
        // });

        $('.header-main-menu .navbar > li.menu-item-has-children > a').on('click', (event) => {
            let currentMainMenu = $(event.currentTarget).closest('li');
            currentMainMenu.addClass('clicked').find('.submenu-wrap').first().addClass('open-submenu');
            $('.header-main-menu .navbar > li.menu-item-has-children').not(currentMainMenu).removeClass('clicked').find('.submenu-wrap').removeClass('open-submenu');
            event.stopPropagation();
            event.preventDefault();
            
        });
        $('.header-main-menu .navbar > li.menu-item-has-children > a').on('click', (event) => {
            // event.stopPropagation();
            // event.preventDefault();
        });
        $(document).on('click', (event) => {
            let target = $(event.target);
            if (!target.closest('.header-main-menu .navbar > li.menu-item-has-children').length) {
                $('.header-main-menu .navbar > li.menu-item-has-children').removeClass('clicked').find('.submenu-wrap').removeClass('open-submenu');
            }
        });


    }


} 
submenu_open_mobile();

let resizeTimeout;
$(window).on('resize', function() {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(submenu_open_mobile, 500);
});

// Button wrap in span
// $('.button, .wp-element-button').each((index, element) => {
//     $(element).wrapInner('<span></span>');
// });

// Readmore
$('.readmore-area').each((index, element) => {
    const moreText = $(element).find('.readmore-toggle').text();
    const lessText = $(element).find('.readmore-toggle').data('less');
    $(element).find('.readmore-toggle').on('click', (event) => {
        $(event.currentTarget).closest('.readmore-area').find('.readmore-content').slideToggle(200);
        // $(event.currentTarget).text($(element).hasClass('active') ? moreText : lessText).toggleClass('active');
        $(event.currentTarget).toggleClass('active');
        if($(event.currentTarget).hasClass('active')){
            $(event.currentTarget).text(lessText);
        } else {
            $(event.currentTarget).text(moreText);
        }
    });
});
$('.readmore-content').each((index, element) => {
    const firstPara = $(element).find('p').first();
    if(firstPara.html().replace(/\s|&nbsp;/g, '').length === 0){
        firstPara.remove();
    }
});

// Lets work popup
$('.lets-work-btn').on('click', (event) => {
    $('html').addClass('lets-talk-show');
});

// To top
$('.to-top').on('click', (event) => {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
});

// Pin / sticky button
let debounceTimeout;

const pinLetsTalk = () => {
    $('.lets-work').each((index, element) => {
        let pin = $(element);
        let pinElementHeight = pin.outerHeight();
        let pinWap = pin.closest('.lets-work-wrap');
        let windowHeight = window.innerHeight;
        let position = pinWap.offset().top - ($(window).scrollTop() + windowHeight - pinElementHeight);

        if (position <= 0) {
            pin.removeClass('pin');
        } else {
            pin.addClass('pin');
        }

        if ($(window).scrollTop() <= windowHeight) {
            pin.removeClass('home-pin');
        } else {
            pin.addClass('home-pin');
        }
    });        
}

$(document).ready(() => {
    pinLetsTalk();
});

$(window).on('scroll', (event) => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
        pinLetsTalk();
    }, 1); 
});

// Show title when tab is inactive
const originalTitle = document.title;
const newTitle = '😥 Miss U. 🙏 Come Back.';
let titleChangeInterval = null;

const titleChange = () => {
    document.title = document.title === originalTitle ? newTitle : originalTitle;
}

document.addEventListener('visibilitychange', () => {
    if (document.hidden) {
        // console.log('hidden');
        clearInterval(titleChangeInterval);
        titleChangeInterval = setInterval(titleChange, 3000);
    } else {
        // console.log('vissible');
        clearInterval(titleChangeInterval);
        document.title = originalTitle;
    }
});

// AOS animation
AOS.init({
    once: true,
    anchorPlacement: 'top-bottom',
    offset: 200,
    duration: 800,
});
